
@import '../../mixins';
@import '../../colors';
@import '../../settings';

.input-group {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  position: relative;

  &__display {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: $input-padding-top-bottom $input-padding-left-right;

    background-color: white;

    border: 1px solid $separation-gray;
    @include border-radius($border-radius);
    @include transition(all $animation-duration);
    position: relative;

    &--flat {
      @include border-radius(0);
      border-left-width: 0;
      border-right-width: 0;
    }

    &--arrow {
      background-image: url("../../assets/images/angle-arrow-down-select.svg");
      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .65em auto, 100%;
    }

    &--information {
      padding: 0;
      background-color: transparent;
      border-color: transparent;
    }

    &--focus {
      padding: $input-padding-top-bottom $input-padding-left-right;
      border-color: $primary;
      background-color: $primary-alpha-5;
    }
  }

  &__label {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 0 .1rem 0;
    padding: 0;

    font-family: 'Open Sans', sans-serif;
    font-size: .8rem;
    font-weight: normal;
    color: $back-arrow-gray;
  }

  &__value {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    color: $black;
  }

  &__input {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    color: $black;

    outline: 0;
    border: 0;
    background-color: transparent;

    &::-webkit-input-placeholder {
      color: $back-arrow-gray;
    }

    &:-ms-input-placeholder {
      color: $back-arrow-gray;
    }

    &::placeholder {
      color: $back-arrow-gray;
    }
  }

  &__button {
    position: absolute;
    right: .7em;
    top: 50%;
    width: auto;
    height: 25%;
    margin: 0;
    padding: 5px;

    cursor: pointer;
    box-sizing: content-box;
    @include transform(translateY(-50%));

    background-color: transparent;
    border: 0;
    @include border-radius(10%);
    outline: 0;

    svg {
      width: auto;
      height: 100%;
    }

    &:hover {
      background-color: $row-hover-gray;
    }

    &:active {
      background-color: $hover-gray;
    }
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    min-height: 0;
    max-height: 200px;
    z-index: 2;
    overflow-y: auto;

    background: white;
    @include border-radius(4px);
    border: 1px solid $separation-gray;
    @include box-shadow(0 3px 6px -1px rgba(0, 0, 0, 0.2));

    &--auto {
      width: 100%;
    }

    &--mid {
      width: 200%;
    }

    &--big {
      width: 300%;
    }
  }

  &__option {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: .4rem .5rem;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    color: $black;

    border-bottom: 1px solid $separation-gray;
    cursor: pointer;

    &:first-child {
      @include border-radius(4px 4px 0 0);
    }

    &:last-child {
      @include border-radius(0 0 4px 4px);
    }

    &:hover {
      background-color: $row-hover-gray;
      color: $black;
    }

    &--active {
      background-color: $hover-gray;
      color: $black;
    }
  }

  &__search {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: .6rem .5rem;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    color: $black;

    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid $separation-gray;
    outline: 0;

    &:focus {
      background-color: $row-hover-gray;
      color: $black;
    }
  }

  &__error {
    display: block;
    width: 100%;
    height: auto;
    margin: .2rem 0 0 0;
    padding: 0;

    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: .8rem;
    color: $delete;
  }

  &__tooltip {
    display: block;
    min-width: 100%;
    height: auto;
    margin: 0;
    padding: $input-tooltip-small-padding-top-bottom $input-tooltip-small-padding-left-right;

    position: absolute;
    top: 105%;
    z-index: 3;

    background-color: white;
    border: 1px solid $separation-gray;
    @include border-radius($border-radius);

    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: $black;
    font-size: .8rem;
    text-decoration: none;
    text-align: left;
    white-space: nowrap;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__icon {
    position: absolute;
    top: 20%;
    right: 5%;

    width: auto;
    height: 60%;
    margin: 0;
    padding: 0;

    cursor: pointer;
  }

  &--text {
    .input-group__display, .input-group__label {
      cursor: text;
    }
  }

  &--select {
    .input-group__display, .input-group__label {
      cursor: pointer;
    }
  }

  &--inline {
    display: inline-block;
    width: auto;
  }
}

.form {
  &__group {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 0 .5rem 0;
    padding: 0;

    h2 {
      display: block;
      margin: 0 0 .3rem 0;
      padding: 0;

      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 1.15rem;
      color: $black;
    }
  }

  &__input {
    margin-bottom: .5rem;
  }
}
