
@import "../../colors";
@import "../../settings";

.table {
  width: 100%;
  margin: 0;
  padding: 0;

  &__content {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__table {
    border-spacing: 0;

    &--nowrap {
      white-space: nowrap;
    }
  }

  thead {
    th {
      padding: $table-header-padding-top-bottom $table-header-padding-left-right;
      border-bottom: 1px solid $separation-gray;

      text-align: left;
      font-family: $table-header-font-family;
      font-size: $table-header-font-size;
      font-weight: normal;
      color: $navigation_gray;
    }
  }

  tbody {
    tr {
      display: table-row;

      td {
        width: auto;
        margin: 0;
        padding: $table-content-padding-top-bottom $table-content-padding-left-right;

        font-family: $table-content-font-family;
        font-weight: normal;
        font-size: $table-content-font-size;
        color: $black;

        border-bottom: 1px solid $separation-gray;
        vertical-align: text-top;

        a {
          color: $secondary;
          font-size: $table-content-font-size;
          font-family: $table-content-font-family;
          font-weight: normal;
          text-decoration: none;
        }

        &.table__cell--subtitle {
          font-weight: normal;
          font-size: 1rem;
          color: $black;

          padding: .3rem ($table-content-padding-left-right + $input-padding-left-right);
          text-align: right;
        }

        &.table__cell--amount {
          font-weight: bold;
          font-size: 1.2rem;
          color: $black;

          padding: .3rem ($table-content-padding-left-right);
          text-align: left;
        }

        &.table__cell--actions {
          text-align: right !important;
        }

        &.table__cell--subitem {
          padding-left: 1.5rem;
        }

        &.table__cell--description, &.table__cell--expandable-description {
          span {
            display: block;
            width: 100%;
            height: auto;
            margin: .1rem 0 0 0;
            padding: 0;

            font-family: "Source Sans Pro", sans-serif;
            font-weight: normal;
            font-size: 0.9rem;
            color: $navigation_gray;
          }
        }

        &.table__cell--expandable-description {
          span {
            cursor: pointer;
          }
        }

        &.table__cell--nowrap {
          white-space: nowrap;
        }

        &.table__cell--loading {
          padding: $table-loading-padding-top-bottom $table-loading-padding-left-right;
          border-width: 0;
        }
      }

      &:hover {
        background-color: $row-hover-gray;
        cursor: pointer;
      }

      &.table__row--subitem {
        background-color: $sub-item-background;
      }

      &.table__row--selectable {
        cursor: pointer;

        &:hover {
          background-color: $row-hover-gray;
        }
      }

      &.table__row--selected {
        background-color: $secondary-alpha-15;
        &:hover {
          background-color: $secondary-alpha-15;
        }
      }

      &.table__row--loading {
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }

  &__pagination {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }
}