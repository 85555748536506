
@import '../../mixins';
@import '../../colors';
@import '../../settings';

$tooltip-separation: 5px;

.tooltip {
  width: min-content;
  position: relative;

  &__content {
    display: block;
    margin: 0;
    padding: $tooltip-padding-top-bottom $tooltip-padding-left-right;

    position: absolute;
    z-index: 3;

    background-color: white;
    border: 1px solid $separation-gray;
    @include border-radius($border-radius);

    font-family: $body-font-family;
    font-size: .9rem;
    font-weight: normal;
    color: $black

    /* Position */

    &--dropdown-left {
      top: calc(100% + #{$tooltip-separation});
      left: 0;
    }

    &--dropdown-right {
      top: calc(100% + #{$tooltip-separation});
      right: 0;
    }

    &--top {
      top: calc(-100% - #{$tooltip-separation});
      left: 50%;
      @include transform(translateX(-50%));
    }

    &--bottom {
      top: calc(100% + #{$tooltip-separation});
      left: 50%;
      @include transform(translateX(-50%));
    }

    &--left {
      left: calc(-100% - #{$tooltip-separation});
      top: 50%;
      @include transform(translateY(-50%));
    }

    &--right {
      left: calc(100% + #{$tooltip-separation});
      top: 50%;
      @include transform(translateY(-50%));
    }

    /* Style */

    &--nowrap {
      white-space: nowrap;
    }

    /* Size */

    &--min {
      width: auto;
      height: auto;
    }

    &--small {
      width: $tooltip-small-width;
      height: auto;
    }

    &--mid {
      width: $tooltip-mid-width;
      height: auto;
    }

    &--big {
      width: $tooltip-big-width;
      height: auto;
    }
  }

  &__info {
    margin-top: .3rem;
  }

  &__title {
    font-family: $header-font-family;
    font-size: 1rem;
    font-weight: bold;
    color: $black
  }
}