
@import "../../settings";
@import "../../mixins";
@import "../../colors";

.labeled-information {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  border: 1px solid transparent;
  @include transition(all $animation-duration);

  &__label {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 0 .1rem 0;
    padding: 0;

    font-family: 'Open Sans', sans-serif;
    font-size: .8rem;
    font-weight: normal;
    color: $back-arrow-gray;
  }

  &__value {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1rem;
    font-weight: normal;
    color: $black;
  }

  a.labeled-information__value {
    color: $secondary;
    text-decoration: none;
  }
}