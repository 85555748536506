
@import "../../mixins";
@import "../../colors";
@import "../../settings";

.select-type {
  &__close {
    text-align: right;
  }

  &__types {
    margin-top: 1rem;
  }

  &__type {
    padding: .7rem;

    @include border-radius($border-radius);

    &--selectable {
      border: 1px solid $separation-gray;
      cursor: pointer;

      &:hover {
        background-color: $row-hover-gray;
      }
    }

    &--information {
      border: none;
    }

  }

  &__icon {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  &__label {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    color: $black;
  }
}