
@import "../../colors";
@import "../../mixins";
@import "../../settings";

.new-offer {
  width: 100%;
  min-height: calc(100vh - #{$header-height}) !important;
  height: auto;
  margin: 0;
  padding: 0;

  &__heading {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 0 1rem 0;
    padding: 0;
  }

  &__navigation {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }

  &__link {
    display: inline;
    margin: 0;
    padding: 0;
    vertical-align: middle;

    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 1.05rem;
    color: $navigation_gray;
    text-decoration: none;
  }

  &__arrow {
    display: inline;
    width: .8rem;
    height: auto;
    margin: 0 .5rem;
    padding: 0;
    vertical-align: middle;

    .icon-color {
      fill: $navigation_gray;
    }
  }

  &__title-container {
    @include flexbox();
    align-items: center;
    width: 100%;
    height: auto;
    margin: 1rem 0 0 0;
    padding: 0;
  }

  &__back {
    @include flex(1);
    max-width: 1.8rem;
    height: auto;
    margin: 0 .5rem 0 0;
    padding: 0;

    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;

    &-icon {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;

      .icon-color {
        fill: $back-arrow-gray;
      }
    }
  }

  &__title {
    @include flex(1);
    margin: 0 1.5rem 0 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
  }

  &__container {
    width: 100%;
    min-height: calc(100vh - #{$header-height});
    height: auto;
    margin: 0;
    padding: 0;

    align-items: stretch;
  }

  &__content {
    @include flex(1);
    max-width: 78%;
    margin: 1.5rem 2% 4rem 2%;

    &--form {
      @include flex(1);
      max-width: 75%;
      margin: 1.5rem auto;
    }
  }

  &__toolbar {
    @include flex(0 0 18%);
    max-width: 18%;
    margin: 0;
    padding: 0;

    position: relative;
    background-color: rgb(252, 252, 253);
    border-left: 1px solid $separation-gray;
  }

  &__step {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: .5rem;
  }

  &__group {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 0 .5rem 0;
    padding: 0;

    h2 {
      display: block;
      margin: 0 0 .3rem 0;
      padding: 0;

      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 1.15rem;
      color: $black;
    }
  }

  &__input {
    margin-bottom: .5rem;
  }

  &__next {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;

    text-align: right;
  }

  &__products {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;

    border-spacing: 0;
  }

  &__product-header {
    padding: .4rem .5rem;
    border-bottom: 1px solid $separation-gray;

    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-size: .9rem;
    font-weight: normal;
    color: $navigation_gray;

    &:first-child {
      padding-left: 0;
    }

    &--add-subitem {
      width: 16px;
      height: auto;
    }

    &--part-number {
      width: 10%;
      height: auto;
    }

    &--name {
      width: auto;
      height: auto;
    }

    &--harmonized-code {
      width: 12%;
      height: auto;
    }

    &--unit {
      width: 6%;
      height: auto;
    }

    &--quantity {
      width: 9%;
      height: auto;
    }

    &--cost {
      width: 9%;
      height: auto;
    }

    &--margin {
      width: 9%;
      height: auto;
    }

    &--total {
      width: 9%;
      height: auto;
    }

    &--actions {
      width: calc(24px + .4rem);
      height: auto;
    }
  }

  &__subtotal {
    margin-top: 1rem;

    &-title {
      padding: 0 2rem;
      text-align: right;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 400;
      font-size: .9rem;
      color: $navigation_gray;
    }

    &-amount {
      padding: 0 2rem;
      text-align: left;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: bold;
      font-size: 1.1rem;
    }
  }
}