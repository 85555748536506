
@import "../../mixins";
@import "../../colors";

$line-width: 20px;
$line-height: 1.5px;

.steps-indicator {
  @include flexbox();
  align-items: center;

  &__step, &__line {
    @include flex(1);
  }

  &__line {
    width: $line-width;
    min-width: $line-width;
    max-width: $line-width;
    height: $line-height;

    background-color: $hover-gray;
    @include transition(all 0.3s);

    &--active {
      background-color: $secondary;
    }
  }

  &__step {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}