
$primary: #F9AD3E;
$secondary: #3E8AF9;
$delete: #f9503e;
$success: #43a047;
$success-dark: #00701a;
$primary-alpha-5: rgba(249, 173, 62, 0.05);
$primary-dark: #e39e39;
$secondary-dark: #3474cf;
$secondary-alpha-15: rgba(62, 138, 249, 0.15);
$secondary-alpha-55: rgba(62, 138, 249, 0.55);
$delete-alpha-5: rgba(249, 80, 62, 0.05);
//$background: #FCFCFD;
$background: rgb(245, 247, 250);
$black: #212121;
$separation-gray: #DFDFDF;
$hover-gray: #e6e6e6;
$navigation_gray: #646464;
$back-arrow-gray: #8a8a8a;
$row-hover-gray: #f1f1f2;
$sub-item-background: #EDEDED;
