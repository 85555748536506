
@import "../../colors";

.checkbox {
  label {
    display: inline-block;
    margin: 0 0 0 .3rem;
    padding: 0;

    font-family: 'Open Sans', sans-serif;
    font-size: .8rem;
    font-weight: normal;
    color: $black;
  }
}