
.offers {
  &__header-cell {
    &--client-name {
      width: 20%;
    }

    &--model {
      width: 40%;
    }
  }

  &__body-cell {
    &--actions {
      text-align: right !important;
    }
  }
}