
.suppliers {
  &__cell {
    &--address {
      width: 30%;
    }
    &--contacts {
      width: 30%;
    }
    &--phones {
      width: 15%;
    }
  }
}