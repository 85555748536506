
@import "../../colors";

.selected-offer {
  &__close {
    text-align: right;
  }

  &__offers {
    margin-top: 1rem;
  }

  &__label {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    color: $black;

    &--title {
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
}
