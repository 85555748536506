
@import '../../colors';

.loading-label {

  &__loader, &__label {
    display: inline-block;
  }

  &__loader {

  }

  &__label {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: .9rem;
    text-align: center;

    &--error {
      color: $delete;
    }

    &--success {
      color: $success;
    }
  }
}
