
@import "../../mixins";
@import "../../colors";

.toolbar {
  display: block;
  width: calc(18% - 1px);
  height: auto;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 50%;
  right: 0;

  @include transform(translateY(-50%));

  &__content {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  &__section {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 0 1.75rem 0;
    padding: 0;

    &--next-button {
      padding-left: .6rem;
      padding-right: .6rem;
    }
  }

  &__input {
    margin: 0;
  }

  &__information {
    @include flexbox();
    align-items: center;
    margin: 0;
    padding: 0 .6rem;
  }

  &__label {
    @include flex(1);
    text-align: left;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    color: $black;
  }

  &__value {
    @include flex(1);
    text-align: right;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    color: $black;
  }
}