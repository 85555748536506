
@import "../../mixins";
@import "../../settings";
@import "../../colors";

.badge {
  display: inline;
  width: auto;
  height: auto;

  color: $black;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  vertical-align: middle;

  border: 1px solid transparent;
  @include border-radius($border-radius);

  background-color: transparent;
  @include transition(all $animation-duration);

  &--small {
    padding: .1rem .3rem;
    margin: .1rem .2rem;

    font-size: .8rem;
  }

  &--mid {
    padding: .3rem .5rem;
    margin: .3rem .5rem;

    font-size: .9rem;
  }

  &--primary {
    color: $primary;
    background-color: transparent;
    border-color: $primary;

    &.badge--fill {
      color: $black;
      background-color: $primary;
      border-color: $primary;
    }
  }

  &--secondary {
    color: $secondary;
    background-color: transparent;
    border-color: $secondary;

    &.badge--fill {
      color: white;
      background-color: $secondary;
      border-color: $secondary;
    }
  }

  &--delete {
    color: $delete;
    background-color: transparent;
    border-color: $delete;

    &.badge--fill {
      color: white;
      background-color: $delete;
      border-color: $delete;
    }
  }

  &--success {
    color: $success;
    background-color: transparent;
    border-color: $success;

    &.badge--fill {
      color: white;
      background-color: $success;
      border-color: $success;
    }
  }
}