
@import '../../colors';
@import '../../mixins';
@import '../../settings';

.menu {
  @include flexbox();
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;

  &__action {
    @include flex(1);
    max-width: min-content;
    margin: 0;
    padding: 1.5rem 1rem;

    color: $black;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
    text-decoration: none;

    border-top: 3px solid transparent;

    @include transition(all $animation-duration);

    & .dropdown__title {
      font-size: 1rem;
      font-weight: 500;
    }

    &--dropdown {
      padding: .8rem 0;
    }

    &--active {
      color: $primary;
      border-top-color: $primary;

      .dropdown__title {
        color: $primary;
      }

      .icon-color {
        fill: $primary;
      }

      .dropdown__toggle--active, .dropdown__toggle--active:hover {
        .dropdown__title {
          color: white;
        }

        .icon-color {
          fill: white;
        }
      }
    }
  }
}
