
@import '../../colors';
@import '../../mixins';
@import '../../settings';

.dropdown {
  &__toggle {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;

    border-width: 0;
    border-style: solid;
    border-color: transparent;
    outline: 0;
    @include border-radius($border-radius);

    cursor: pointer;
    color: $black;
    background-color: transparent;
    @include transition(all $animation-duration);

    .icon-color {
      @include transition(all $animation-duration);
    }

    &--outline {
      border-width: 1px;
    }

    &--disabled {
      cursor: default;
    }
  }

  &__icon {
    display: inline-block;
    vertical-align: middle;
  }

  &__title {
    display: inline-block;
    vertical-align: middle;

    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    white-space: nowrap;
  }

  &__caret {
    display: inline-block;
    vertical-align: middle;
  }

  &--small {
    .dropdown {
      &__toggle {
        padding: $dropdown-small-padding-top-bottom $dropdown-small-padding-left-right;
      }

      &__icon {
        width: .8rem;
        height: auto;
        margin: 0 .1rem 0 0;
        padding: 0;
      }

      &__title {
        margin: 0 .2rem 0 .2rem;
        padding: 0;

        font-size: .8rem;
      }

      &__caret {
        width: .5rem;
        height: auto;
        margin: 0 0 0 .1rem;
        padding: 0;
      }
    }
  }

  &--mid {
    .dropdown {
      &__toggle {
        padding: $dropdown-mid-padding-top-bottom $dropdown-mid-padding-left-right;
        font-size: 1.05rem;
      }

      &__icon {
        width: 1.3rem;
        height: auto;
        margin: 0 .2rem 0 0;
        padding: 0;
      }

      &__title {
        margin: 0 .3rem 0 .3rem;
        padding: 0;

        font-size: 1rem;
      }

      &__caret {
        width: .6rem;
        height: auto;
        margin: 0 0 0 .2rem;
        padding: 0;
      }
    }
  }

  &--nav {
    .dropdown {
      &__toggle {
        padding: $dropdown-nav-padding-top-bottom $dropdown-nav-padding-left-right;
      }

      &__icon {
        width: 1.1rem;
        height: auto;
        margin: 0 .2rem 0 0;
        padding: 0;
      }

      &__title {
        margin: 0 .3rem 0 .3rem;
        padding: 0;

        font-size: 1rem;
      }

      &__caret {
        width: .7rem;
        height: auto;
        margin: 0 0 0 .2rem;
        padding: 0;
      }
    }
  }

  &--primary {
    .dropdown {
      &__toggle {
        border-color: $primary;

        &:hover {
          background-color: $hover-gray;
        }

        &--active, &--active:hover {
          background-color: $primary;
          color: white;

          .icon-color {
            fill: white;
          }
        }
      }
    }
  }

  &--secondary {
    .dropdown {
      &__toggle {
        background-color: $secondary;
        border-color: $secondary;
        color: white;

        .icon-color {
          fill: white;
        }

        &:hover {
          background-color: $secondary-dark;
          color: white;

          .icon-color {
            fill: white;
          }
        }

        &--active, &--active:hover {
          background-color: $secondary-dark;
          color: white;

          .icon-color {
            fill: white;
          }
        }
      }
    }
  }

  &--success {
    .dropdown {
      &__toggle {
        background-color: $success;
        border-color: $success;
        color: white;

        .icon-color {
          fill: white;
        }

        &:hover {
          background-color: $success-dark;
          color: white;

          .icon-color {
            fill: white;
          }
        }

        &--active, &--active:hover {
          background-color: $success-dark;
          color: white;

          .icon-color {
            fill: white;
          }
        }
      }
    }
  }

  &--gray {
    .dropdown {
      &__toggle {
        border-color: $back-arrow-gray;

        &:hover {
          background-color: $hover-gray;
        }

        &--active, &--active:hover {
          background-color: $back-arrow-gray;
          color: white;

          .icon-color {
            fill: white;
          }
        }
      }
    }
  }

  &--transparent {
    .dropdown {
      &__toggle {
        background-color: transparent;
        border-color: transparent;
        color: $black;

        .icon-color {
          fill: $black;
        }

        &:hover {
          background-color: $hover-gray;
        }

        &:active, &--active:hover {
          background-color: $back-arrow-gray;
        }
      }
    }
  }
}
