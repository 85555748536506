
@import '../../colors';
@import '../../settings';

.app {
  display: block;
  width: 100%;
  min-height: 100% !important;
  height: auto;
  margin: 0;
  padding: 0;

  background: $background;

  &__content {
    min-height: calc(100vh - #{$header-height}) !important;
    height: 100%;
    padding: 0;
    margin: 0;
  }
}
