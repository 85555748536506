
@import "../../colors";

.quote-preview {
  &__title {
    font-family: 'Open Sans', sans-serif;
    font-size: .8rem;
    font-weight: normal;
    color: $back-arrow-gray;

    margin-bottom: .1rem;
  }

  &__data {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: .9rem;
    font-weight: normal;
    color: $black;

    margin-bottom: .3rem;
  }

  &__link {
    text-align: center;
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
}