
@import '../../mixins';

.loading-button {
  position: relative;

  &__content {

    &--hide {
      @include opacity(0);
    }
  }

  &__loader {
    display: block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;

    position: absolute;
    top: 55%;
    left: 50%;

    @include transform(translateX(-50%) translateY(-50%));
  }
}
