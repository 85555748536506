
.shipment-form {
  min-width: 65%;
}

/*
.page__list {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
}

thead {
  display: table-header-group;

  th {
    padding: .4rem 15px;
    border-bottom: 1px solid $separation-gray;

    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-size: .9rem;
    font-weight: normal;
    color: $navigation_gray;
  }
}

tbody {
  display: table-row-group;

  .page__list-item {
    display: table-row;
    cursor: pointer;

    td {
      padding: .3rem 15px;
      border-bottom: 1px solid $separation-gray;

      text-align: left;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1rem;
      font-weight: normal;
      color: $black;

      a {
        margin: 0;
        padding: 0;

        color: $secondary;
        text-align: left;
        text-decoration: none;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1rem;
        font-weight: normal;
      }

      .page-list__action {
        display: inline-block;
        margin: 0;
      }
    }

    &:hover {
      background-color: $row-hover-gray;
    }

    &--loading:hover {
      background-color: transparent;
      cursor: default;
    }

    td.page__list-cell--loading {
      padding: .6rem 15px;
      border-width: 0;
    }
  }
}

thead, tbody {
  margin: 0;
  padding: 0 15px;
}

.page__pagination {
  margin-top: 1.3rem;
  margin-bottom: 0;
}*/
