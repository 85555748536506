
@import "../../colors";
@import "../../settings";

.product-item {
  &__cell {
    width: auto;
    margin: 0;
    padding: $table-content-padding-top-bottom $table-content-padding-left-right;

    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    color: $black;

    border-bottom: 1px solid $separation-gray;
    vertical-align: text-top;

    /*&:first-child {
      padding-left: 0;
    }*/

    /*.input-group__container, .input-group--focus > .input-group__container {
      padding: .2rem .3rem;
    }*/

    .product-item__add-subitem {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      outline: 0;
      cursor: pointer;
      background: transparent;
      border: 0;

      svg {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
      }
    }

    a {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: normal;
      font-size: 1rem;
      color: $secondary;
      text-decoration: none;
    }

    &--item-number { width: 5%; }
    &--amount { width: 9%; }
    &--type { width: 8%; }
    &--code { width: 9%; }
    &--actions {
      width: min-content;

      /*.product-item__action {
        padding: .05rem .4rem;
      }
      .btn__icon {
        width: 1rem;
      }*/
    }

    &--selection { max-width: min-content ! important; }
    &--add-subitem {
      min-width: $add-subitem-width;
      max-width: $add-subitem-width;
      vertical-align: middle;
      height: auto;
      padding: 0 6px 0 0;
    }

    &--sub-item {
      padding-left: 1.5rem;
    }
  }

  &--sub-item {
    background-color: $sub-item-background;
    //td {
    //  background-color: $sub-item-background;
    //}

    .product-item--selected {
      background-color: $secondary-alpha-15;
    }
  }

  &--new {
    td {
      border-bottom: 0;
    }
  }

  &--selected {
    background-color: $secondary-alpha-15;
  }

  &__description {
    display: block;
    width: 100%;
    height: auto;
    margin: .1rem 0 0 0;
    padding: 0;

    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    color: $navigation_gray;

    &--expandable {
      cursor: pointer;
    }
  }
}
