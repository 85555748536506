
@import "../../settings";
@import "../../colors";
@import "../../mixins";

.user-form {
  &__roles {
    margin-top: 1rem;
  }

  &__role {
    min-width: 0;
    margin: 0;
    padding: .5rem 1rem;

    border: 1px solid $separation-gray;
    @include border-radius($border-radius);

    cursor: pointer;

    h4 {
      margin: 0 0 .3rem 0;
      padding: 0;

      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      font-size: 1rem;
      color: $black;
    }

    span {
      display: block;
      margin: 0;
      padding: 0;

      font-family: 'Source Sans Pro', sans-serif;
      font-weight: normal;
      font-size: .8rem;
      color: $navigation_gray;

      white-space: nowrap;
      text-overflow:ellipsis;
      overflow: scroll;
    }

    &:hover {
      background-color: $row-hover-gray;
    }

    &--active, &--active:hover {
      background-color: $hover-gray;
    }
  }
}