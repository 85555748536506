
@import "../../colors";
@import "../../settings";

.products-list {
  width: 100%;
  margin: 0;
  padding: 0;

  border-spacing: 0;

  &__header {
    padding: $table-header-padding-top-bottom $table-header-padding-left-right;
    border-bottom: 1px solid $separation-gray;

    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-size: .9rem;
    font-weight: normal;
    color: $navigation_gray;

    /*&:first-child {
      padding-left: 0;
    }*/

    &--min {
      max-width: min-content !important;
    }
  }

  &__subtitle {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    color: $black;

    padding: .3rem ($table-content-padding-left-right + $input-padding-left-right);
    text-align: right;
  }

  &__amount {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    color: $black;

    padding: .3rem ($table-content-padding-left-right);
    text-align: left;
  }

  &__loading-cell {
    padding: .4rem 15px;
    border-width: 0;
  }
}
