
@import "../../mixins";

* { @include box-sizing(border-box); }

html, body, #root {
  min-height: 100% !important;
  height: auto;
  padding: 0;
  margin: 0;
}
#root {
  height: auto;
  padding: 0;
  margin: 0;
}

/** Import dependencies **/

@import "./loading-block";
@import "./input-group";
@import "./button";
@import "./columns";
@import "./card";
