
@import "../../mixins";
@import "../../settings";

.card {
  padding: 1rem;
  margin-bottom: 1rem;

  background-color: white;
  border: 1px solid rgb(243, 244, 245);
  @include border-radius($border-radius);

  h2 {
    margin-top: 0;
    margin-bottom: 1rem;

    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 1.1rem;
    color: $black;
  }

  h3 {
    margin-top: .8rem;
    margin-bottom: .2rem;

    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: .9rem;
    color: $black;
  }

  &__instructions {
    margin-top: .3rem;
    margin-bottom: 0;

    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    color: $black;
  }

  &__actions {
    text-align: right;
    white-space: nowrap;
  }

  //&__body {
  //  margin-top: 1rem;
  //}
}
