
@import '../../colors';

.pagination {
  &__status {
    white-space: nowrap;
    margin: 0;
    padding: 0 15px;

    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    color: $back-arrow-gray;
  }

  &__page-selector {
    text-align: right;
  }

  &__page {
    padding: .3rem .4rem;
    font-weight: 400;
    vertical-align: middle;
    color: $back-arrow-gray;

    &--active {
      color: $black !important;
    }
  }

  &__arrow {
    display: inline;
    width: .8rem;
    height: auto;
    margin: 0 .5rem;
    padding: 0;
    vertical-align: middle;

    .icon-color {
      fill: $back-arrow-gray;
    }
  }

  &__page-input {
    text-align: right;
    white-space: nowrap;

    span {
      display: inline-block;
      vertical-align: middle;
      margin: 0 .5rem 0 0;

      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      font-size: 0.9rem;
      color: $back-arrow-gray;
    }

    .input-group__input {
      display: inline-block;
      vertical-align: middle;
      width: 3.5rem;
      text-align: center;
    }
  }

  &--margin-page {
    margin-top: 1.3rem;
    margin-bottom: 0;
  }

  &--margin-select {
    margin-top: 1rem;
  }
}