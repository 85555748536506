
.clients {
  &__header-cell {
    &--address {
      width: 40%;
    }
    &--contacts {
      width: 40%;
    }
  }
}