
@import '../../colors';
@import '../../mixins';
@import '../../settings';

.dropdown {
  &__options {
    display: block;
    width: auto;
    min-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    z-index: 3;

    position: absolute;

    background: white;
    border: 1px solid $separation-gray;
    @include border-radius($border-radius);

    &--left {
      right: 0;
    }

    &--right {
      left: 0;
    }
  }

  &--small {
    .dropdown__options {
      top: 105%;
    }
  }

  &--mid {
    .dropdown__options {
      top: 110%;
    }
  }
}
