
.dashboard {
  &__offers {
    &__header--client {
      width: 50%;
    }
  }

  &__invoices {
    &__header--client {
      width: 20%;
    }

    &__header--description {
      width: 40%;
    }
  }

  &__products {
    &__header--description {
      width: 80%;
    }
  }
}