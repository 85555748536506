
@import '../../colors';
@import '../../mixins';
@import '../../settings';

.new-list-item {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: .5rem .7rem;

  @include border-radius($border-radius);
  border: 1px solid $separation-gray;

  background: transparent;
  @include transition(all $animation-duration);

  &__content {
    margin-bottom: 0.5rem;

    .input-group {
      margin-bottom: .3rem;

      &__input[type="text"], &__input[type="email"], &__input[type="tel"], select.input-group__input {
        background-color: transparent;
      }
    }
  }

  &__footer {
    text-align: right;
  }

  &:focus-within {
    background: white;
    @include box-shadow(0 2px 4px 0 rgba(0, 0, 0, 0.2));

    .input-group__input[type="text"]:focus, .input-group__input[type="email"]:focus, .input-group__input[type="tel"]:focus, select.input-group__input:focus {
      background-color: $primary-alpha-5;
    }
  }
}
