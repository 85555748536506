
.shipments {
  &__header-cell {
    &--manifiesto {
      width: 40%;
    }
  }

  &__body-cell {
    &--actions {
      text-align: right !important;
    }
  }
}