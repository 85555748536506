
.invoices {
  &__header-cell {
    &--client-name {
      width: 15%;
    }

    &--description {
      width: 25%;
    }
  }

  &__body-cell {
    &--actions {
      text-align: right !important;
    }
  }
}