
@import "../../mixins";
@import "../../colors";
@import "../../settings";

.page-header {
  @include flexbox();
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  //background-color: red;

  &__navigation {
    @include flex(0 0 100%);
    min-width: 100%;
    margin: 0;
    padding: 0;
  }

  &__link {
    display: inline;
    margin: 0;
    padding: 0;
    vertical-align: middle;

    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 1.05rem;
    color: $navigation_gray;
    text-decoration: none;
  }

  &__arrow {
    display: inline;
    width: .8rem;
    height: auto;
    margin: 0 .5rem;
    padding: 0;
    vertical-align: middle;

    .icon-color {
      fill: $navigation_gray;
    }
  }

  &__back {
    @include flex(0 0 1.8rem);
    max-width: 1.8rem;
    height: auto;
    margin: 0 .5rem 0 0;
    padding: 0;

    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;

    &-icon {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;

      .icon-color {
        fill: $back-arrow-gray;
      }
    }
  }

  &__title {
    @include flex(0 0 min-content);
    max-width: min-content;
    margin: 0 .5rem 0 0;
    padding: 0;

    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
    white-space: nowrap;

    //background-color: green;
  }

  &__types {
    @include flex(0 0 min-content);
    max-width: min-content;
    margin: 0 .5rem 0 0;
    padding: 0;

    //background-color: yellow;
  }

  &__filters {
    @include flex(1);
    margin: 0;
    padding: 0;
    text-align: left;

    //background-color: blue;
  }

  &__filter {
    display: inline-block;
    margin-left: .5rem;
    margin-right: .5rem;

    &:first-child {
      margin-left: 0;
    }
  }

  &__actions {
    @include flex(1);
    margin: 0;
    padding: 0;
    text-align: right;

    //background-color: blue;
  }

  &__action {
    display: inline-block;
    margin-left: .5rem;
    margin-right: .5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
