
@import "../../mixins";
@import "../../settings";

.loading-block {
  display: block;
  width: 100%;
  height: auto;
  padding: .7rem 0;

  &--small {
    padding: .4rem 0;
  }

  &--mid {
    padding: .55rem 0;
  }

  background: rgb(238, 238, 238);
  @include border-radius($border-radius);

  @include animation(loading-block-animation 1s 0s infinite);
}

@include keyframes(loading-block-animation) {
  0% {
    background-color: rgb(238, 238, 238);
  }
  50% {
    background-color: rgb(230, 230, 230);
  }
  100% {
    background-color: rgb(238, 238, 238);
  }
}