
.dropdown {
  display: block;
  margin: 0 .2rem;
  padding: 0;
  position: relative;
  white-space: nowrap;

  &--inline {
    display: inline-block;
  }
}
