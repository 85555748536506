
@import '../../settings';
@import '../../colors';

.package-products-selection {
  &__product {
    &--selected, &--selected:hover {
      background-color: $secondary-alpha-15 !important;
    }
  }

  &__add-btn {
    text-align: right;
  }

  &__products {
    margin-top: .5rem;
  }
}