
@import "../../colors";

.new-invoice {
  width: 70%;
  &--full {
    width: 96%;
  }
}



$block-width: 66.666667%;

/*.new-invoice {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  &__header {
    padding: 1rem 2%;
  }

  &__main {
    margin: 1rem 2%;
    padding: 0;
  }

  &__block {
    margin-bottom: 1rem;

    h3 {
      margin-top: .8rem;
      margin-bottom: .2rem;

      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: .9rem;
      color: $black;
    }
  }

  &__next {
    text-align: right;
  }
}*/
