
$header-font-family: 'Open Sans', sans-serif;
$body-font-family: 'Source Sans Pro', sans-serif;

$animation-duration: 0.2s;
$border-radius: 4px;
$header-height: 74px;

$input-padding-top-bottom: .3rem;
$input-padding-left-right: .6rem;
$input-tooltip-small-padding-top-bottom: .3rem;
$input-tooltip-small-padding-left-right: .5rem;

$button-micro-padding-top-bottom: .1rem;
$button-micro-padding-left-right: .3rem;
$button-small-padding-top-bottom: .3rem;
$button-small-padding-left-right: .5rem;

$button-table-padding-top-bottom: .3rem;
$button-table-padding-left-right: .5rem;
$button-table-font-size: 0.7rem;

$button-mid-padding-top-bottom: .4rem;
$button-mid-padding-left-right: .7rem;

$dropdown-small-padding-top-bottom: .3rem;
$dropdown-small-padding-left-right: .5rem;
$dropdown-mid-padding-top-bottom: .4rem;
$dropdown-mid-padding-left-right: .7rem;
$dropdown-nav-padding-top-bottom: .7rem;
$dropdown-nav-padding-left-right: 1rem;

$badge-small-padding-top-bottom: .1rem;
$badge-small-padding-left-right: .3rem;
$badge-small-margin-top-bottom: .1rem;
$badge-small-margin-left-right: .2rem;

/** ----- Table ----- **/

$table-header-padding-top-bottom: .4rem;
$table-header-padding-left-right: .2rem;
$table-header-font-family: $header-font-family;
$table-header-font-size: .8rem;

$table-content-padding-top-bottom: .2rem;
$table-content-padding-left-right: .2rem;
$table-content-font-family: $body-font-family;
$table-content-font-size: .9rem;

$table-loading-padding-top-bottom: .6rem;
$table-loading-padding-left-right: 15px;

$add-subitem-width: 22px;

/** ----- Tooltip ----- **/

$tooltip-padding-top-bottom: .3rem;
$tooltip-padding-left-right: .5rem;

$tooltip-small-width: 250px;
$tooltip-mid-width: 450px;
$tooltip-big-width: 700px;
