
@import '../../colors';
@import '../../mixins';
@import "../../settings";

.header {
  @include flexbox();
  align-items: center;
  width: 100%;
  height: $header-height;
  margin: 0;
  padding: 0 2%;

  background: white;
  border-bottom: 1px solid $separation-gray;

  &__logo {
    @include flex(1);
    max-width: min-content !important;
    height: 32px;
    margin: 0;
    padding: 0;

    cursor: pointer;

    img {
      display: block;
      width: auto;
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }

  &__menu {
    @include flex(2);
    height: auto;
    margin: 0 0 0 1rem;
    padding: 0;
  }

  &__actions {
    @include flex(1);
    width: auto;
    max-width: min-content !important;
    height: auto;
    margin: 0;
    padding: 0;
  }

  &__profile {
    @include flex(1);
    width: auto;
    max-width: min-content !important;
    height: auto;
    margin: 0;
    padding: 0;
  }
}
