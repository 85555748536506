
@import '../../colors';
@import '../../mixins';
@import '../../settings';

.dropdown {
  &__link {
    display: block;
    width: 100%;
    height: auto;

    background: transparent;
    @include transition(all $animation-duration);

    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: $black;
    text-decoration: none;
    text-align: left;

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: $hover-gray;
    }
  }

  &--small {
    .dropdown {
      &__link {
        margin: 0;
        padding: $dropdown-small-padding-top-bottom $dropdown-small-padding-left-right;

        font-size: .8rem;
      }
    }
  }

  &--mid {
    .dropdown {
      &__link {
        margin: 0;
        padding: $dropdown-mid-padding-top-bottom $dropdown-mid-padding-left-right;

        font-size: .9rem;
      }
    }
  }

  &--nav {
    .dropdown {
      &__link {
        margin: 0;
        padding: $dropdown-nav-padding-top-bottom $dropdown-nav-padding-left-right;

        font-size: .9rem;
      }
    }
  }
}
