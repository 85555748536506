
@import "../../mixins";
@import "../../settings";
@import "../../colors";

$dot-size-small: 6px;
$dot-size-mid: 10px;

.dot {
  display: block;

  &__dot {
    display: inline-block;
    vertical-align: baseline;

    @include border-radius(1rem);

    /* Sizes */

    &--small {
      min-width: $dot-size-small;
      width: $dot-size-small;
      max-width: $dot-size-small;
      min-height: $dot-size-small;
      height: $dot-size-small;
      max-height: $dot-size-small;
    }

    &--mid{
      min-width: $dot-size-mid;
      width: $dot-size-mid;
      max-width: $dot-size-mid;
      min-height: $dot-size-mid;
      height: $dot-size-mid;
      max-height: $dot-size-mid;
    }

    /* Colors */

    &--black {
      background-color: $black;
    }

    &--success {
      background-color: $success;
    }

    &--error {
      background-color: $delete;
    }
  }

  &__label {
    display: inline-block;
    vertical-align: baseline;

    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    color: $black;

    &--small {
      font-size: .8rem;
      margin-left: .5rem;
    }

    &--mid {
      font-size: 1rem;
      margin-left: .5rem;
    }
  }
}