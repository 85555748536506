
.form-list-item {
  display: block;
  width: 100%;
  height: auto;

  & .input-group {
    margin-bottom: .3rem;
  }
}
