
@import "../../colors";

.package-item {
  &__loader {
    text-align: right;
  }

  &__details {
    padding-bottom: 1rem;
    border-bottom: 1px solid $separation-gray;
  }

  &__products {
    margin-top: 1rem;
  }
}