
@import "../../settings";
@import "../../colors";
@import "../../mixins";

.btn {
  width: auto;
  height: auto;
  margin: 0;
  padding: $button-mid-padding-top-bottom $button-mid-padding-left-right;

  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;

  cursor: pointer;
  outline: 0;
  border: 1px solid transparent;
  @include appearance(none);
  @include border-radius($border-radius);

  background-color: transparent;
  @include transition(all $animation-duration);

  &__icon {
    display: inline-block;
    width: 1.1rem;
    height: auto;
    margin: 0;
    padding: 0;

    vertical-align: middle;
  }

  &__title {
    display: inline-block;
    margin: 0 .5rem;
    padding: 0;

    vertical-align: middle;
  }

  /** Position */

  &--inline {
    display: inline-block;
  }

  /** Sizes **/

  &--micro {
    margin: 0;
    padding: $button-micro-padding-top-bottom $button-micro-padding-left-right;

    font-size: .6rem;

    .btn__icon {
      width: .85rem;
      height: auto;
      margin: 0;
      padding: 0;
    }
  }

  &--table {
    margin: 0;
    padding: $button-table-padding-top-bottom $button-table-padding-left-right;

    font-size: $button-table-font-size;

    .btn__icon {
      width: .90rem;
      height: auto;
      margin: 0;
      padding: 0;
    }
  }

  &--small {
    margin: 0;
    padding: $button-small-padding-top-bottom $button-small-padding-left-right;

    font-size: .8rem;

    .btn__icon {
      width: .95rem;
      height: auto;
      margin: 0;
      padding: 0;
    }
  }

  &--full {
    width: 100%;
  }

  &--badge {
    margin: $badge-small-margin-top-bottom $badge-small-margin-left-right;
    padding: $badge-small-padding-top-bottom $badge-small-padding-left-right;

    font-size: .7rem;

    .btn__icon {
      width: .95rem;
      height: auto;
      margin: 0;
      padding: 0;
    }
  }

  /** Colors **/

  &--primary {
    background-color: $primary;
    border-color: $primary;
    color: $black;

    .icon-color {
      fill: $black;
    }

    &:hover, &:active {
      background-color: $primary-dark;
    }

    &.btn--outline {
      color: $primary;

      &:hover, &:active {
        background-color: $primary;
        color: $black;
      }
    }
  }

  &--secondary {
    background-color: $secondary;
    border-color: $secondary;
    color: white;

    .icon-color {
      fill: white;
    }

    &:hover, &:active {
      background-color: $secondary-dark;
    }

    &.btn--outline {
      color: $secondary;

      &:hover, &:active {
        background-color: $secondary;
        color: white;
      }
    }
  }

  &--gray {
    background-color: $back-arrow-gray;
    border-color: $back-arrow-gray;
    color: white;

    .icon-color {
      fill: $black;
    }

    &:hover, &:active {
      background-color: $back-arrow-gray;
    }

    &.btn--outline {
      color: $back-arrow-gray;

      &:hover {
        background-color: $hover-gray;
      }

      &:active {
        background-color: $back-arrow-gray;
      }
    }
  }

  &--transparent {
    background-color: transparent;
    border-color: transparent;
    color: $black;

    .icon-color {
      fill: $black;
    }

    &:hover {
      background-color: $hover-gray;
    }

    &:active {
      background-color: $back-arrow-gray;
    }
  }

  &--error {
    background-color: $delete;
    border-color: $delete;
    color: white;

    .icon-color {
      fill: white;
    }

    &:hover, &:active {
      background-color: $delete;
    }

    &.btn--outline {
      color: $delete;

      &:hover, &:active {
        background-color: $delete;
        color: white;
      }
    }
  }

  &--success {
    background-color: $success;
    border-color: $success;
    color: white;

    .icon-color {
      fill: white;
    }

    &:hover, &:active {
      background-color: $success;
    }

    &.btn--outline {
      color: $success;

      &:hover, &:active {
        background-color: $success;
        color: white;
      }
    }
  }

  /** Styles **/

  &--outline {
    border-width: 1px;
    background-color: transparent;
  }

  &--nowrap {
    white-space: nowrap;
  }

  &--link {
    margin: 0 !important;
    padding: 0 !important;

    color: $secondary;
    text-align: left;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    text-decoration: none;
    font-weight: normal;

    &.btn--table {
      font-size: $table-content-font-size;
    }
  }

  /*&--icon {
    background: transparent;
    border: 0;

    &:hover {
      background: $hover-gray;
    }

    &.btn--red {
      .icon-color {
        fill: $delete;
      }
    }

    &.btn--secondary {
      .icon-color {
        fill: $secondary;
      }
    }
  }

  &.btn--transparent {
    background: transparent;
    border: 0;
    color: $back-arrow-gray;

    .icon-color {
      fill: $back-arrow-gray;
    }

    &:hover, &:active {
      background: $hover-gray;
    }
  }*/
}