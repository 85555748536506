
/**
Open Sans
 */

/**@font-face {
  font-family: 'Open Sans';
  src: url("open-sans/Light/OpenSans-Light.eot?v=1.1.0");
  src: url("open-sans/Light/OpenSans-Light.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("open-sans/Light/OpenSans-Light.woff2?v=1.1.0") format("woff2"), url("open-sans/Light/OpenSans-Light.woff?v=1.1.0") format("woff"), url("open-sans/Light/OpenSans-Light.ttf?v=1.1.0") format("truetype"), url("open-sans/Light/OpenSans-Light.svg?v=1.1.0#Light") format("svg");
  font-weight: 300;
  font-style: normal;
}**/

@font-face {
  font-family: 'Open Sans';
  src: url("open-sans/Regular/OpenSans-Regular.eot?v=1.1.0");
  src: url("open-sans/Regular/OpenSans-Regular.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("open-sans/Regular/OpenSans-Regular.woff2?v=1.1.0") format("woff2"), url("open-sans/Regular/OpenSans-Regular.woff?v=1.1.0") format("woff"), url("open-sans/Regular/OpenSans-Regular.ttf?v=1.1.0") format("truetype"), url("open-sans/Regular/OpenSans-Regular.svg?v=1.1.0#Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("open-sans/Semibold/OpenSans-Semibold.eot?v=1.1.0");
  src: url("open-sans/Semibold/OpenSans-Semibold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("open-sans/Semibold/OpenSans-Semibold.woff2?v=1.1.0") format("woff2"), url("open-sans/Semibold/OpenSans-Semibold.woff?v=1.1.0") format("woff"), url("open-sans/Semibold/OpenSans-Semibold.ttf?v=1.1.0") format("truetype"), url("open-sans/Semibold/OpenSans-Semibold.svg?v=1.1.0#Semibold") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("open-sans/Bold/OpenSans-Bold.eot?v=1.1.0");
  src: url("open-sans/Bold/OpenSans-Bold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("open-sans/Bold/OpenSans-Bold.woff2?v=1.1.0") format("woff2"), url("open-sans/Bold/OpenSans-Bold.woff?v=1.1.0") format("woff"), url("open-sans/Bold/OpenSans-Bold.ttf?v=1.1.0") format("truetype"), url("open-sans/Bold/OpenSans-Bold.svg?v=1.1.0#Semibold") format("svg");
  font-weight: bold;
  font-style: normal;
}

/**
Source Sans Pro
 */

@font-face {
  font-family: 'Source Sans Pro';
  src: url("source-sans-pro/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("source-sans-pro/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/**@font-face {
  font-family: 'Source Sans Pro';
  src: url("source-sans-pro/SourceSansPro-") format("truetype");
  font-weight: 600;
  font-style: normal;
}**/
