
@import "../../colors";

.empty-notification {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 4rem;

  text-align: center;

  &__content {
    display: block;
    width: 50%;
    height: auto;
    margin: 0 auto;
    padding: 0;
  }

  &__icon {
    display: block;
    width: 17%;
    height: auto;
    margin: 0 auto 2rem auto;
    padding: 0;
  }

  &__title {
    margin: 0;
    padding: 0;

    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold !important;
    font-size: 1.5rem !important;
    color: $black;
  }

  &__details {
    margin: 1rem auto 0 auto;
    padding: 0;

    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: normal !important;
    font-size: 1rem !important;
    color: $black;
  }

  &__action {
    margin-top: 1.5rem;
  }
}