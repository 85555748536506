
@import "../../colors";

.change-password {
  &__instructions {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    color: $black;

    margin-top: .5rem;
  }

  &__advises {
    margin-top: .5rem;
  }

  &__advise {
    margin-bottom: .3rem;
  }
}