
@import '../../colors';
@import '../../mixins';

.spinner {
  display: block;
  width: 100%;
  min-width: 70px;
  height: auto;
  margin: 0;
  padding: 0;

  text-align: center;

  &--inline {
    display: inline-block;
  }

  & > div {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;

    @include border-radius(100%);
    @include animation(sk-bouncedelay 1.4s infinite ease-in-out both);
  }

  & .bounce1 {
    @include animation-delay(-0.32s);
  }

  & .bounce2 {
    @include animation-delay(-0.16s);
  }

  &--white > div {
    background: white;
  }

  &--black > div {
    background: $black;
  }

  &--small {
    min-width: 45px;
    & > div {
      width: 10px;
      height: 10px;
    }
  }

  &--mid {
    min-width: 70px;
    & > div {
      width: 16px;
      height: 16px;
    }
  }
}

@include keyframes(sk-bouncedelay) {
  0%, 80%, 100% { @include transform(scale(0)); }
  40% { @include transform(scale(1.0)); }
}
