
@import './mixins';
@import './colors';
@import './settings';

.page {
  display: block;
  width: 96%;
  height: auto;
  margin: 1.5rem auto;
  padding: 0;

  white-space: normal;

  /**
  Header
   */

  &__heading {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 0 1rem 0;
    padding: 0;
  }

  &__navigation {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }

  &__link {
    display: inline;
    margin: 0;
    padding: 0;
    vertical-align: middle;

    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 1.05rem;
    color: $navigation_gray;
    text-decoration: none;
  }

  &__arrow {
    display: inline;
    width: .8rem;
    height: auto;
    margin: 0 .5rem;
    padding: 0;
    vertical-align: middle;

    .icon-color {
      fill: $navigation_gray;
    }
  }

  &__title-container {
    display: block;
    width: 100%;
    height: auto;
    margin: 1rem 0 0 0;
    padding: 0;
  }

  &__back {
    width: 1.8rem;
    height: auto;
    margin: 0 .5rem 0 0;
    padding: 0;

    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;

    &-icon {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;

      .icon-color {
        fill: $back-arrow-gray;
      }
    }
  }

  &__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
  }

  /**
  Section
   */

  &__block {
    margin-bottom: 1rem;
  }

  .page__input {
    margin-bottom: .3rem;
  }

  &__content {
    width: 100%;
    height: auto;
    margin: 0;
  }

  &__section {
    width: 100%;
    height: auto;
    margin: 0 0 1rem 0;
    padding: 0;
  }

  &__subtitle {
    @include flexbox();
    width: 100%;
    height: auto;
    margin: 0 0 .3rem 0;
    padding: 0;

    h2 {
      @include flex(1);
      margin: 0;
      padding: 0;

      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 1.15rem;
      color: $black;
    }

    button {
      @include flex(1);
      max-width: 1.1rem;
      margin: 0;
      padding: 0;

      outline: 0;
      border: 0;
      background: transparent;
      cursor: pointer;

      svg {
        display: block;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
      }

      .icon-color {
        fill: $secondary;
      }
    }
  }

  &__list {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    border-bottom: 1px solid $separation-gray;
  }

  &__submit {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;

    text-align: right;
  }

  &__card {
    margin-bottom: 1rem;
  }

  /**
  Form modifications
   */

  &--form {
    width: min-content;
    min-width: 50%;
    max-width: 96%;

    .page__back {
      display: inline;
      vertical-align: middle;
    }

    .page__title {
      display: inline;
      margin: 0;
      padding: 0;
      vertical-align: middle;
    }

    .page__input {
      margin-bottom: .5rem;
    }

    .page__new-item {
      display: block;
      width: 100%;
      height: auto;
      margin: .5rem 0;
      padding: 0;
    }

    .page__list {
      display: block;
      width: 100%;
    }

    .page__list-item {
      display: block;
      width: 100%;
      margin: 0 0 .5rem 0;
      padding: 0 0 .5rem 0;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  /**
  List modifications
   */

  &--list {
    width: 96%;

    .page__title-container {
      @include flexbox();
      align-items: center;
    }

    .page__back {
      @include flex(1);
      max-width: 1.8rem;
    }

    .page__title {
      @include flex(1);
      margin: 0 1.5rem 0 0;
      padding: 0;
    }

    .page__new-button {
      @include flex(1);
      margin: 0;
      padding: 0;
    }

    .page__list {
      display: table;
      width: 100%;
      margin: 0;
      padding: 0;
      border-spacing: 0;

      &--nowrap {
        white-space: nowrap;
      }
    }
/*
    thead {
      display: table-header-group;

      th {
        padding: .4rem 15px;
        border-bottom: 1px solid $separation-gray;

        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: .9rem;
        font-weight: normal;
        color: $navigation_gray;
      }
    }

    tbody {
      display: table-row-group;

      .page__list-item {
        display: table-row;
        cursor: pointer;

        td {
          padding: .3rem 15px;
          border-bottom: 1px solid $separation-gray;

          text-align: left;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1rem;
          font-weight: normal;
          color: $black;

          a {
            margin: 0;
            padding: 0;

            color: $secondary;
            text-align: left;
            text-decoration: none;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 1rem;
            font-weight: normal;
          }

          .page-list__action {
            display: inline-block;
            margin: 0;
          }
        }

        &:hover {
          background-color: $row-hover-gray;
        }

        &--loading:hover {
          background-color: transparent;
          cursor: default;
        }

        td.page__list-cell--loading {
          padding: .6rem 15px;
          border-width: 0;
        }
      }
    }

    thead, tbody {
      margin: 0;
      padding: 0 15px;
    }*/

    .page__pagination {
      margin-top: 1.3rem;
      margin-bottom: 0;
    }
  }
}
