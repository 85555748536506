
.products {
  &__header-cell {
    &--description {
      width: 50%;
    }
  }

  &__body-cell {
    &--actions {
      text-align: right !important;
    }
  }
}