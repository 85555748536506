
@import "../../mixins";
@import "../../colors";

$step-size: 20px;

.step {
  white-space: nowrap;

  &__number {
    display: inline-block;
    width: $step-size;
    height: $step-size;
    margin: 0;
    padding: 0;

    text-align: center;
    vertical-align: middle;
    line-height: $step-size;

    font-family: "Open Sans", sans-serif;
    font-size: .7rem;
    font-weight: 400;
    background-color: $hover-gray;
    color: $navigation_gray;

    @include border-radius(50%);
    @include transition(all 0.3s);
  }

  &__label {
    display: inline-block;
    margin: 0 0 0 .4rem;
    padding: 0;

    font-family: "Open Sans", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    color: $navigation_gray;

    vertical-align: middle;
    @include transition(all 0.3s);
  }

  &--incomplete {
    .step__number {
      font-weight: 400;
      background-color: $hover-gray;
      color: $navigation_gray;
    }

    .step__label {
      color: $navigation_gray;
    }
  }

  &--active {
    .step__number {
      background-color: $secondary;
      color: white;
      font-weight: bold;
    }

    .step__label {
      color: $secondary;
      font-weight: bold;
    }
  }

  &--completed {
    .step__number {
      background-color: $secondary;
      color: white;
      font-weight: normal;
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: auto;
        fill: white;
        @include transform(translate(-50%, -50%));
      }
    }

    .step__label {
      color: $secondary;
      font-weight: normal;
    }
  }
}