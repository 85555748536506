
@import "../../mixins";
@import "../../colors";

$illustration-width: 60%;
$quote-bottom-distance: 10%;

$content-width: 100% - $illustration-width;
$content-padding: 4rem;

.sign-in {
  @include flexbox();
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  margin: 0;
  padding: 0;

  background-color: white;

  // Illustration

  &__illustration {
    position: relative;
    @include flex(1);
    align-self: stretch;
    max-width: $illustration-width;
    min-height: 100%;
    margin: 0;
    padding: 0;

    background-image: url("../../assets/images/sign-in-background1.jpg");
    // background-image: url("../../assets/images/sign-in-background2.jpg");
    // background-image: url("../../assets/images/sign-in-background3.jpg");
    background-position: left center;
    background-size: cover;
  }

  &__quote {
    position: absolute;
    bottom: $quote-bottom-distance;
    left: 5%;

    h1 {
      margin: 0;
      padding: 0;

      color: white;
      font-family: 'Open Sans', sans-serif;
      font-size: 2.3rem;
      font-weight: bold;
    }

    span {
      margin: 0;
      padding: 0;

      color: white;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  // Content

  &__content {
    @include flex(1);
    max-width: $content-width;
    min-height: 100%;
    margin: 0;
    padding: $content-padding;

    span {
      display: block;
      margin: 0 0 1.5rem 0;
      padding: 0;

      font-family: 'Source Sans Pro', sans-serif;
      font-weight: normal;
      font-size: 1.1rem;
      color: $black;
    }
  }

  &__logo {
    width: 40%;
    height: auto;
    margin: 0 0 2rem 0;
    padding: 0;

    img {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
    }
  }

  &__input {
    margin-top: 1.3rem;
  }

  &__submit {
    margin-top: 2rem;
  }
}